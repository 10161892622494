import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  > div {
    font-size: 44px;
    padding: 20px;
    font-weight: 500;
  }
  
  img {
    width: 600px;
  }
`

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 24px;
`

export const Col3Wrapper = styled.div`
  width: calc((100% / 3) - 12px);
  min-height: 100px;
  position: relative;
`
