export const initialState = {
  loading: false,
  isLoadingData: false,
  isSaving: false,
  isDeleting: false,
  isReady: false,
  errors: null,
  listAll: [],
  listData: [],
  listCount: 0,
  listParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 20,
  },
  listFilters: {
    searchText: "",
    branch_id: "all"
  },
};
