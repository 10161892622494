import React, {Suspense, useState} from 'react';
import {Outlet} from "react-router-dom";
import {Layout} from 'antd';
import {
  RootLayout,
  RootContent
} from "./styled";
import SplashScreen from "components/SplashScreen";
import Sidebar from "./components/Sidebar";
import Appbar from "./components/Appbar";
import {BranchProvider} from "contexts/BranchProvider";
import {DocumentProvider} from "contexts/DocumentProvider";

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isReady, setIsReady] = useState(false)
  return (
    <RootLayout>
      <Sidebar
        collapsed={collapsed}
        isReady={isReady}
        setIsReady={setIsReady}
      />
      {!isReady && <SplashScreen />}
      {isReady && (
        <Layout>
          <DocumentProvider>
            <Appbar
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <RootContent>
              <Suspense fallback={<SplashScreen />}>
                <BranchProvider>
                  <Outlet />
                </BranchProvider>
              </Suspense>
            </RootContent>
          </DocumentProvider>
        </Layout>
      )}
    </RootLayout>
  );
};
export default MainLayout;
