import styled from "styled-components";
import {Card} from "antd";
import {colors} from "utils/colors";

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  
  .ant-select {
    width: 130px;
    align-self: end;

    .ant-select-selector {
      border: 0 !important;
      box-shadow: unset !important;
      background: rgb(0 51 156 / 10%);
    }

    .ant-select-selection-item {
      color: ${colors.primary};
      font-weight: 500;
    }
  }

  > div:nth-child(2) {
    background: ${colors.primary};
  }

  > div:nth-child(3) {
    background: ${colors.warning};
  }

  > div:nth-child(4) {
    background: ${colors.danger};
  }
`

export const StyledCard = styled(Card)`
  .ant-card-body {
    display: flex;
    align-items: start;
  }
  .ant-statistic {
    flex-grow: 1;
  }
  .ant-statistic-title {
    font-weight: 600;
    color: #fff !important;
  }
  .ant-statistic-content {
    color: #fff !important;
    font-size: 26px;
    font-weight: 600;
    margin-top: 5px;
  }
  svg {
    height: 40px;
    width: 40px;
    path {
      fill: rgba(255, 255, 255, 0.4);
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled.div`
  flex-grow: 1;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.gray[500]};
`
