import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  MailOutlined,
  LockOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Form,
  Input
} from 'antd';
import {
  ErrorWrapper,
  ForgotPassword,
  FormContent,
  FormFooter,
  FormHeader,
  FormWrapper,
  Root
} from "./styled";
import {useAuthContext} from "contexts/AuthProvider";

const inputRules = [
  {
    required: true,
  },
]

const Login = () => {

  const {
    state,
    login,
    loading
  } = useAuthContext();

  const navigate = useNavigate()
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    let success = await login(values);

    if(success) {
      navigate("/admin/dashboard")
    }
  }

  return (
    <Root>
      <FormWrapper>
        <FormHeader>
          <img alt={"branch-logo"} src={"/images/logo.png"} />
        </FormHeader>
        <FormContent>
          <div>
            <div>Welcome back!</div>
            <div>Enter your credentials to access your account.</div>
            <ErrorWrapper>
              {state.error && (
                <Alert
                  message="Invalid credentials."
                  type="error"
                  showIcon
                />
              )}
            </ErrorWrapper>
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <Form.Item
                name={"email"}
                label="Email address"
                rules={inputRules}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  prefix={<MailOutlined />}
                  autoComplete={"off"}
                />
              </Form.Item>
              <Form.Item
                name={"password"}
                label="Password"
                rules={inputRules}
              >
                <Input
                  type={"password"}
                  size="large"
                  placeholder="Password"
                  prefix={<LockOutlined />}
                  autoComplete={"off"}
                />
              </Form.Item>
              <Form.Item>
                <ForgotPassword>
                  Forgot password?
                </ForgotPassword>
                <SubmitButton form={form} loading={loading}>
                  {loading
                    ? (
                      <>
                        <LoadingOutlined />
                        Logging in ...
                      </>
                    )
                    : <>Log In</>
                  }
                </SubmitButton>
              </Form.Item>
            </Form>
          </div>
        </FormContent>
        <FormFooter>
          2023 SSM, All rights Reserved
        </FormFooter>
      </FormWrapper>
    </Root>
  );
}

const SubmitButton = ({ form, loading, children }) => {

  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable || loading}
      size={"large"}
    >
      {children}
    </Button>
  );
};

export default Login
