import { createContext, Fragment, useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {
  deleteRequest,
  getRequest,
  postRequest
} from "utils/request";
import {initialState} from "./constant";

const DocumentContext = createContext(initialState)

const DocumentProvider = ({ children }) => {

  const navigate = useNavigate()
  const [state, setState] = useState(initialState)

  const updateState = (newState) => {
    setState({
      ...state,
      ...newState
    });
  }

  const updateListParams = (newParams) => {
    updateState({
      listParams: {
        ...state.listParams,
        ...newParams
      }
    })
  }

  const updateListFilters = (newFilters) => {
    updateState({
      listFilters: {
        ...state.listFilters,
        ...newFilters
      }
    })
  }

  const resetData = () => {
    updateState({
      isLoadingData: true,
      isSaving: false,
      saveSuccess: false,
      isDeleting: false,
      isReady: false,
      errors: null,
    })
  }

  const resetSorting = () => {
    updateState({
      listParams: {
        ...state.listParams,
        sortField: initialState.listParams.sortField,
        sortOrder: initialState.listParams.sortOrder
      }
    })
  }

  const fetchAll = async () => {
    updateState({ loading: true });
    let res = await getRequest(`/api/fetch-documents`);
    if(res.ok) {
      updateState({
        listAll: res.data.data,
        loading: false
      });
    } else {
      updateState({ loading: false });
    }
  }

  const fetchList = async () => {
    if(state.loading) return

    updateState({ loading: true });

    const params = `?page=${state.listParams.pageNumber}
      &pageSize=${state.listParams.pageSize}
      &sortField=${state.listParams.sortField}
      &sortOrder=${state.listParams.sortOrder}
      &searchField=${state.listFilters.searchField}
      &searchText=${state.listFilters.searchText}`;

    let res = await getRequest(`/api/documents${params}`);

    if(res.ok) {
      updateState({
        listData: res.data.data,
        listCount: res.data.meta.total,
        loading: false,
        isReady: true
      });
    } else {
      console.log(res);
      updateState({ loading: false });
    }
  }

  const show = async (document_id) => {
    updateState({ isLoadingData: true });
    let res = await getRequest(`/api/documents/${document_id}`);

    updateState({ isLoadingData: false });
    return res.ok ? res.data.data : null
  }

  const save = async (payload) => {
    updateState({
      errors: null,
      saveSuccess: false,
      isSaving: true
    });

    let url = `/api/documents`;

    if(payload.id) {
      url += `/${payload.id}`;
      payload['_method'] = 'PUT';
    }

    let res = await postRequest(url, payload)

    if(res.ok) {
      updateState({
        isSaving: false,
        saveSuccess: true,
        errors: null,
      });

      return res.data.data;
    } else {
      if(res.hasOwnProperty('status') && res.status === 422) {
        updateState({
          errors: res.data.errors,
          isSaving: false,
        });
        return false;
      }
    }
  }

  const deleteData = async (document_id) => {
    updateState({ isDeleting: true });
    let res = await deleteRequest(`/api/documents/${document_id}`)
    updateState({ isDeleting: false });
    if(res.ok) {
      await fetchList();
      message.success("Document successfully deleted.")
    }
  }

  const fetchAllExpiry = async () => {
    if(state.loading) return

    updateState({ loading: true });

    let res = await getRequest(`/api/fetch-expiry-documents`);
    if(res.ok) {
      updateState({
        listAllExpiry: res.data,
        loading: false
      });
    } else {
      updateState({ loading: false });
    }
  }

  const markedAsDone = async (payload) => {
    let url = `/api/document-mark-as-done/${payload.id}`;
    payload._method = "PUT"

    let res = await postRequest(url, payload)

    if(res.ok) {
      fetchAllExpiry()
      message.success("Reminder successfully updated.")
      return res.data.data;
    }
  }

  return (
    <DocumentContext.Provider value={{
      navigate,
      state,
      updateState,
      updateListParams,
      updateListFilters,
      resetSorting,
      fetchAll,
      fetchList,
      show,
      save,
      deleteData,
      fetchAllExpiry,
      markedAsDone
    }}>
      <Fragment>
        {children}
      </Fragment>
    </DocumentContext.Provider>
  )
}

const useDocumentContext = () => {
  const context = useContext(DocumentContext);
  if(!context) {
    throw new Error("useDocumentContext must be used within DocumentProvider")
  }
  return context;
}

export {DocumentProvider, useDocumentContext}
