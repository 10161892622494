import React from "react";
import {
  HomeOutlined,
  CalculatorOutlined,
  PieChartOutlined,
  RiseOutlined,
  SwapOutlined,
  ProductOutlined,
  TeamOutlined,
  ShopOutlined,
  FileProtectOutlined,
  InteractionOutlined,
  SettingOutlined,
  WalletOutlined,
  AppstoreAddOutlined,
  CreditCardOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";


export const menuItems = [
  {
    key: 'dashboard',
    icon: <HomeOutlined />,
    label: 'Dashboard',
    permissions: [],
  },
  {
    key: 'management',
    icon: <SettingOutlined />,
    label: 'Management',
    permissions: ['list_users', 'list_roles', 'list_branches'],
    children: [
      { key: 'branches', label: 'Branches', permissions: ['list_branches'] },
      { key: 'users', label: 'Users', permissions: ['list_users'] },
      { key: 'roles', label: 'Roles', permissions: ['list_roles'] },
    ],
  },
  {
    key: 'services',
    icon: <InteractionOutlined />,
    label: 'Services',
    permissions: ['list_services', 'list_partners', 'list_vehicles', 'list_drivers', 'list_award_memos', 'list_billings'],
    children: [
      {key: 'transactions', label: 'Transactions', permissions: ['list_services']},
      {key: 'billings', label: 'Billings', permissions: ['list_billings']},
      {key: 'award-memos', label: 'Award Memos', permissions: ['list_award_memos']},
      {key: 'partners', label: 'Partners', permissions: ['list_partners']},
      {key: 'vehicles', label: 'Vehicles', permissions: ['list_vehicles']},
      {key: 'drivers', label: 'Drivers', permissions: ['list_drivers']},
    ],
  },
  {
    key: 'cash-on-hand',
    icon: <CalculatorOutlined />,
    label: 'Cash on hand',
    permissions: ['list_cash_on_hand'],
  },
  {
    key: 'expenses',
    icon: <PieChartOutlined />,
    label: 'Expenses',
    permissions: ['list_expenses'],
  },
  {
    key: 'sales',
    icon: <RiseOutlined />,
    label: 'Sales',
    permissions: ['list_sales'],
  },
  {
    key: 'payments',
    icon: <WalletOutlined />,
    label: 'Payments',
    permissions: ['list_payments'],
  },
  {
    key: 'purchases',
    icon: <ShoppingCartOutlined />,
    label: 'Purchases',
    permissions: ['list_purchases'],
  },
  {
    key: 'pullouts',
    icon: <AppstoreAddOutlined />,
    label: 'Pullouts',
    permissions: ['list_pullouts'],
  },
  {
    key: 'payables',
    icon: <CreditCardOutlined />,
    label: 'Payables',
    permissions: ['list_payables'],
  },
  {
    key: 'transfer-stocks',
    icon: <SwapOutlined />,
    label: 'Transfer stocks',
    permissions: ['list_transfer_stocks'],
  },
  {
    key: 'products',
    icon: <ProductOutlined />,
    label: 'Products',
    permissions: ['list_products'],
  },
  {
    key: 'customers',
    icon: <TeamOutlined />,
    label: 'Customers',
    permissions: ['list_customers'],
  },
  {
    key: 'suppliers',
    icon: <ShopOutlined />,
    label: 'Suppliers/Clients',
    permissions: ['list_suppliers'],
  },
  {
    key: 'documents',
    icon: <FileProtectOutlined />,
    label: 'Documents',
    permissions: ['list_documents'],
  },
]
