import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url("/images/logo.png") no-repeat center;
    background-size: 300px;
    transition: opacity 0.45s ease-in-out;
    opacity: 0.1;
    z-index: -1;
  }
`
