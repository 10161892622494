import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState
} from "react";
import {
  getRequest,
  postRequest
} from "utils/request";
import LoggingOut from "components/LoggingOut";
import {isLoggedIn} from "utils/auth";

const initialState = {
  token: "",
  roles: [],
  permissions: [],
  user: {
    id: "",
    name: "",
    email: "",
    avatar: ""
  }
}

const AuthContext = createContext(initialState)

const AuthProvider = ({ children }) => {

  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  useEffect(() => {
    if(isLoggedIn()
      && !state.user.name
      && localStorage.getItem('user') !== undefined
    ){
      setState({
        user: JSON.parse(localStorage.getItem('user')),
        roles: JSON.parse(localStorage.getItem('roles')),
        permissions: JSON.parse(localStorage.getItem('permissions')),
      })
    }
  }, [])

  const updateState = (key, value) => {
    setState({
      ...state,
      [key]: value
    })
  }

  const resetState = () => {
    setState(initialState)
  }

  const login = async (payload) => {
    setLoading(true)
    updateState("error", "")

    await getRequest(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`)

    let res = await postRequest(`/api/login`, payload)

    if(res.ok) {
      let {data: data} = res
      let token = data.data.token.split("|")[1];

      setState({
        token: token,
        user: data.data.user,
        roles: data.data.roles,
        permissions: data.data.permissions
      });

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(data.data.user));
      localStorage.setItem('roles', JSON.stringify(data.data.roles));
      localStorage.setItem('permissions', JSON.stringify(data.data.permissions));

      setLoading(false)

      return true
    } else {
      updateState("error", "Invalid credentials.")

      setLoading(false)

      return false
    }
  }

  const logout = () => {
    setIsLoggingOut(true)

    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('roles')
    localStorage.removeItem('permissions')

    resetState()
  }

  return (
    <AuthContext.Provider value={{
      loading,
      state,
      login,
      logout,
      resetState,
      setIsLoggingOut
    }}>
      <Fragment>
        {isLoggingOut && <LoggingOut />}
        {children}
      </Fragment>
    </AuthContext.Provider>
  )
}

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if(!context) {
    throw new Error("useAuthContext must be used within AuthProvider")
  }
  return context;
}

export {AuthProvider, useAuthContext}
