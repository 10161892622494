import {createBrowserRouter} from "react-router-dom";
import guest_routes from "./guest_routes";
import auth_routes from "./auth_routes";
import admin_routes from "./admin_routes";

const router = createBrowserRouter([
  ...guest_routes,
  ...auth_routes,
  ...admin_routes
]);

export default router
