import React from "react";
import {Link} from "react-router-dom";

const Homepage = () => {
  return(
    <div>
      Home page - public
      <div>
        <Link to={"/auth/login"}>login</Link>
        <br />
        <Link to={"/admin"}>protected</Link>
      </div>
    </div>
  )
}

export default Homepage
