import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: rgb(0, 0, 0, 0.5);
  color: white;
  font-family: 'Quicksand';
  font-weight: 600;
  
  svg {
    font-size: 30px;
  }
`
