import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`
