import {RouterProvider} from "react-router-dom";
import theme from "theme";
import router from "routes";
import {ConfigProvider} from "antd";
import {AuthProvider} from "contexts/AuthProvider";

export default function App() {
  return (
    <ConfigProvider theme={theme}>
      <AuthProvider>
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </AuthProvider>
    </ConfigProvider>
  );
}
