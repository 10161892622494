export const isLoggedIn = () => {
  return localStorage.hasOwnProperty('token')
    && localStorage.token !== ""
    && localStorage.hasOwnProperty('user')
    && localStorage.user !== "";
}

export const isAdmin = () => {
  let user_roles = JSON.parse(localStorage.getItem('roles'))
  return ["super-admin", "admin"].some(role => user_roles.indexOf(role) !== -1);
}

export const getMyBranchId = () => {
  let user = JSON.parse(localStorage.getItem('user'))
  return user['branch_id'] ? parseInt(user['branch_id']) : ""
}

export const hasPermissions = (permissions) => {
  if(!permissions) return true
  let user_permissions = JSON.parse(localStorage.getItem('permissions'))
  return permissions.some(permission => user_permissions.indexOf(permission) !== -1);
}

export const getUserRole = () => {
  let roles = JSON.parse(localStorage.roles)
  return roles.join(", ");
}
