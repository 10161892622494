import { createContext, Fragment, useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {
  deleteRequest,
  getRequest,
  postRequest
} from "utils/request";
import {initialState} from "./constant";

const BranchContext = createContext(initialState)

const BranchProvider = ({ children }) => {

  const navigate = useNavigate()
  const [state, setState] = useState(initialState)

  const updateState = (newState) => {
    setState({
      ...state,
      ...newState
    });
  }

  const updateListParams = (newParams) => {
    updateState({
      listParams: {
        ...state.listParams,
        ...newParams
      }
    })
  }

  const updateListFilters = (newFilters) => {
    updateState({
      listFilters: {
        ...state.listFilters,
        ...newFilters
      }
    })
  }

  const resetSorting = () => {
    updateState({
      listParams: {
        ...state.listParams,
        sortField: initialState.listParams.sortField,
        sortOrder: initialState.listParams.sortOrder
      }
    })
  }

  const fetchAll = async () => {
    updateState({ loading: true });
    let res = await getRequest(`/api/fetch-branches`);
    if(res.ok) {
      updateState({
        listAll: res.data.data,
        loading: false
      });
    } else {
      updateState({ loading: false });
    }
  }

  const fetchList = async () => {
    if(state.loading) return

    updateState({ loading: true });

    const params = `?page=${state.listParams.pageNumber}
      &pageSize=${state.listParams.pageSize}
      &sortField=${state.listParams.sortField}
      &sortOrder=${state.listParams.sortOrder}
      &searchText=${state.listFilters.searchText}`;

    let res = await getRequest(`/api/branches${params}`);

    if(res.ok) {
      updateState({
        listData: res.data.data,
        listCount: res.data.meta.total,
        loading: false,
        isReady: true
      });
    } else {
      console.log(res);
      updateState({ loading: false });
    }
  }

  const show = async (branch_id) => {
    updateState({ isLoadingData: true });
    let res = await getRequest(`/api/branches/${branch_id}`);

    updateState({ isLoadingData: false });
    return res.ok ? res.data.data : null
  }

  const save = async (payload) => {
    updateState({
      errors: null,
      isSaving: true
    });

    let url = `/api/branches`;

    if(payload.id) {
      url += `/${payload.id}`;
      payload['_method'] = 'PUT';
    }

    let res = await postRequest(url, payload)

    if(res.ok) {
      updateState({ isSaving: false });

      fetchList()

      return true;
    } else {
      if(res.hasOwnProperty('status') && res.status === 422) {
        updateState({
          errors: res.data.errors,
          isSaving: false,
        });
        return false;
      }
    }
  }

  const deleteData = async (branch_id) => {
    updateState({ isDeleting: true });
    let res = await deleteRequest(`/api/branches/${branch_id}`)
    updateState({ isDeleting: false });
    if(res.ok) {
      await fetchList();
      message.success("Branch successfully deleted.")
    }
  }

  return (
    <BranchContext.Provider value={{
      navigate,
      state,
      updateState,
      updateListParams,
      updateListFilters,
      resetSorting,
      fetchAll,
      fetchList,
      show,
      save,
      deleteData,
    }}>
      <Fragment>
        {children}
      </Fragment>
    </BranchContext.Provider>
  )
}

const useBranchContext = () => {
  const context = useContext(BranchContext);
  if(!context) {
    throw new Error("useBranchContext must be used within BranchProvider")
  }
  return context;
}

export {BranchProvider, useBranchContext}
