import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
  BellOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Menu,
  Popover,
  Tooltip
} from 'antd';
import {
  RootHeader,
  LeftContent,
  RightContent,
  NotificationWrapper
} from "./styled";
import DrawerNotification from "../DrawerNotification";
import ModalUserProfile from "../ModalUserProfile";
import {useAuthContext} from "contexts/AuthProvider";
import {UserProvider} from "contexts/UserProvider";
import {useDocumentContext} from "contexts/DocumentProvider";

const Appbar = ({
  collapsed,
  setCollapsed
}) => {

  const {
    state,
    logout,
    setIsLoggingOut
  } = useAuthContext()
  const DocumentContext = useDocumentContext()

  const navigate = useNavigate()
  const [openAvatar, setOpenAvatar] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openUserModal, setOpenUserModal] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleAvatar = () => {
    setOpenAvatar(!openAvatar)
  }
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleLogout = async () => {
    await logout()

    toggleAvatar()

    setTimeout(() => {
      setIsLoggingOut(false)
      navigate("/auth/login")
    }, 500)
  }

  const toggleUserModal = () => {
    setOpenAvatar(false)
    setOpenUserModal(!openUserModal)
  }

  return (
    <UserProvider>
      <RootHeader className={scrollPosition > 78 ? "scrolled" : ""}>
        <LeftContent>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </LeftContent>
        <RightContent>
          <NotificationWrapper>
            <Badge count={DocumentContext.state.listAllExpiry?.count}>
              <Tooltip title={"View Reminders"}>
                <Avatar
                  onClick={toggleDrawer}
                  icon={<BellOutlined />}
                />
              </Tooltip>
            </Badge>
          </NotificationWrapper>
          <Divider type={"vertical"} />
          <Popover
            trigger="click"
            title={`Hi, ${state.user.name}`}
            open={openAvatar}
            onOpenChange={toggleAvatar}
            content={(
              <Menu
                mode="vertical"
                style={{borderInlineEnd: 0}}
                selectedKeys={[]}
              >
                <Menu.Item
                  icon={<SettingOutlined />}
                  onClick={toggleUserModal}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Menu.Item>
              </Menu>
            )}
          >
            {state.user.avatar ? (
              <Avatar
                size={"large"}
                src={state.user.avatar}
              />
            ) : <Avatar>{state.user.name.charAt(0)}</Avatar>}
          </Popover>
        </RightContent>
        <DrawerNotification
          openDrawer={openDrawer}
          toggleDrawer={toggleDrawer}
        />
        {openUserModal && (
          <ModalUserProfile
            open={openUserModal}
            toggleOpen={toggleUserModal}
          />
        )}
      </RootHeader>
    </UserProvider>
  );
};
export default Appbar;
