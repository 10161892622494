import Homepage from "../pages/Homepage";
import GuestLayout from "../layouts/GuestLayout";
import {redirect} from "react-router-dom";

const guest_routes = [
  {
    path: "/",
    loader: guestLoader,
    Component: GuestLayout,
    children:[
      {
        index: true,
        Component: Homepage
      }
    ]
  },
];

export default guest_routes

function guestLoader() {
  return redirect("/admin/dashboard");
}
