import styled from "styled-components";
import {Form} from "antd";

const { Item } = Form

export const FormItem = styled(Item)`
  .ant-alert-with-description {
    padding: 14px !important;
  }
  ul {
    margin-block: 2px;
    padding-inline-start: 16px;
  }
`
