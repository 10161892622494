export const initialState = {
  loading: false,
  isLoadingData: true,
  isSaving: false,
  saveSuccess: false,
  isDeleting: false,
  isReady: false,
  errors: null,
  listAll: [],
  listAllExpiry: [],
  listData: [],
  listCount: 0,
  listParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 20,
  },
  listFilters: {
    searchField: "document_number",
    searchText: "",
    branch_id: "all"
  },
  listFilterFields: [
    {value: "document_number", label: "Search by doc. number"},
    {value: "document_type", label: "Search by doc. type"},
    {value: "holder_name", label: "Search by holder"},
    {value: "issuing_authority", label: "Search by Issuing Authority"},
    {value: "status", label: "Status"},
  ],
  documentTypes: [
    { value: "business_permit", label: "Business Permit" },
    { value: "license", label: "Licence" },
    { value: "registration", label: "Registration" },
    { value: "permit", label: "Permit" },
    { value: "certificate", label: "Certificate" },
  ],
  statusOptions: [
    { value: "active", label: "Active" },
    { value: "expired", label: "Expired" },
    { value: "revoke", label: "Revoke" },
    { value: "renewed", label: "Renewed" },
  ]
};
