import styled from "styled-components";
import {Layout} from "antd";
import {colors} from "utils/colors";

const { Header } = Layout;

export const RootHeader = styled(Header)`
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px !important;
  max-height: 65px;

  &.scrolled {
    box-shadow: 0 2px 2px -2px #ccc;
  }

  button {
    font-size: 16px;
  }
`

export const LeftContent = styled.div`
  flex-grow: 1;
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 18px;

  .ant-divider {
    border-color: #8f8f8f;
  }

  .ant-avatar {
    border: 1px solid ${colors.primary};
    font-weight: 600;
    background: ${colors.primary} !important;
    cursor: pointer;
  }
`

export const NotificationWrapper = styled.div`
  button {
    height: unset !important;
    padding: 8px !important;
    border-radius: 50%;
    margin-top: 16px;
    margin-right: -14px;
    font-size: 18px;
    color: #8f8f8f;
  }

  .ant-avatar {
    color: ${colors.gray[700]};
    border: 1px solid ${colors.gray[100]};
    font-weight: 600;
    background: ${colors.gray[100]} !important;
    cursor: pointer;
  }
`
