import React from "react";
import {Outlet} from "react-router-dom";
import {Root} from "./styled";

const AuthLayout = () => {
  return (
    <Root>
      <Outlet />
    </Root>
  )
}

export default AuthLayout
