import {lazy} from "react";
import {redirect, Navigate, useLocation} from "react-router-dom";
import Dashboard from "pages/Dashboard";
import MainLayout from "layouts/MainLayout";
import {hasPermissions, isLoggedIn} from "utils/auth";

const AwardMemos = lazy(() => import("pages/AwardMemos"))
const Billings = lazy(() => import("pages/Billings"))
const BillingsEdit = lazy(() => import("pages/BillingsEdit"))
const BillingsHaulingWastePdf = lazy(() => import("pages/BillingsHaulingWastePdf"))
const BillingsQuarryPdf = lazy(() => import("pages/BillingsQuarryPdf"))
const BillingsQuarryPartnerPdf = lazy(() => import("pages/BillingsQuarryPartnerPdf"))
const BillingsHaulingColoredWaterPdf = lazy(() => import("pages/BillingsHaulingColoredWaterPdf"))
const Branches = lazy(() => import("pages/Branches"))
const CashOnHand = lazy(() => import("pages/CashOnHand"))
const CashOnHandPdf = lazy(() => import("pages/CashOnHandPdf"))
const Companies = lazy(() => import("pages/Companies"))
const CompaniesEdit = lazy(() => import("pages/CompaniesEdit"))
const Customers = lazy(() => import("pages/Customers"))
const CustomersEdit = lazy(() => import("pages/CustomersEdit"))
const CustomersTransaction = lazy(() => import("pages/CustomersTransaction"))
const Drivers = lazy(() => import("pages/Drivers"))
const Documents = lazy(() => import("pages/Documents"))
const DocumentsEdit = lazy(() => import("pages/DocumentsEdit"))
const Expenses = lazy(() => import("pages/Expenses"))
const ExpensesEdit = lazy(() => import("pages/ExpensesEdit"))
const Partners = lazy(() => import("pages/Partners"))
const PartnersEdit = lazy(() => import("pages/PartnersEdit"))
const Payables = lazy(() => import("pages/Payables"))
const PayablesEdit = lazy(() => import("pages/PayablesEdit"))
const Payments = lazy(() => import("pages/Payments"))
const PaymentsEdit = lazy(() => import("pages/PaymentsEdit"))
const Products = lazy(() => import("pages/Products"))
const ProductsEdit = lazy(() => import("pages/ProductsEdit"))
const Pullouts = lazy(() => import("pages/Pullouts"))
const PulloutsEdit = lazy(() => import("pages/PulloutsEdit"))
const Purchases = lazy(() => import("pages/Purchases"))
const PurchasesEdit = lazy(() => import("pages/PurchasesEdit"))
const Roles = lazy(() => import("pages/Roles"))
const RolesEdit = lazy(() => import("pages/RolesEdit"))
const Sales = lazy(() => import("pages/Sales"))
const SalesEdit = lazy(() => import("pages/SalesEdit"))
const Services = lazy(() => import("pages/Services"))
const ServicesEdit = lazy(() => import("pages/ServicesEdit"))
const TransferStocks = lazy(() => import("pages/TransferStocks"))
const Users = lazy(() => import("pages/Users"))
const Vehicles = lazy(() => import("pages/Vehicles"))
const VehiclesEdit = lazy(() => import("pages/VehiclesEdit"))
const Unauthorized = lazy(() => import("pages/Unauthorized"))

const ProtectedRoute = ({ element, permissions }) => {
  const location = useLocation();
  if (!hasPermissions(permissions)) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }
  return element;
};

const admin_routes = [
  {
    path: "/admin",
    loader: protectedLoader,
    Component: MainLayout,
    children: [
      {
        index: true,
        Component: Dashboard,
      },
      {
        path: "dashboard",
        Component: Dashboard,
      },
      {
        path: "award-memos",
        element: (
          <ProtectedRoute
            permissions={['list_award_memos']}
            element={<AwardMemos />}
          />
        )
      },
      {
        path: "billings",
        element: (
          <ProtectedRoute
            permissions={['list_billings']}
            element={<Billings />}
          />
        )
      },
      {
        path: "billings/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_billings', 'edit_billings']}
            element={<BillingsEdit />}
          />
        )
      },
      {
        path: "branches",
        element: (
          <ProtectedRoute
            permissions={['list_branches']}
            element={<Branches />}

          />
        )
      },
      {
        path: "cash-on-hand",
        Component: CashOnHand
      },
      {
        path: "suppliers",
        element: (
          <ProtectedRoute
            permissions={['list_suppliers']}
            element={<Companies />}
          />
        )
      },
      {
        path: "suppliers/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_suppliers', 'edit_suppliers']}
            element={<CompaniesEdit />}
          />
        )
      },
      {
        path: "customers",
        Component: Customers,
        element: (
          <ProtectedRoute
            permissions={['list_customers']}
            element={<Customers />}
          />
        )
      },
      {
        path: "customers/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_customers', 'edit_customers']}
            element={<CustomersEdit />}
          />
        )
      },
      {
        path: "customers/transactions/:customer_id",
        element: (
          <ProtectedRoute
            permissions={['create_customers', 'edit_customers']}
            element={<CustomersTransaction />}
          />
        )
      },
      {
        path: "drivers",
        element: (
          <ProtectedRoute
            permissions={['list_drivers']}
            element={<Drivers />}
          />
        )
      },
      {
        path: "documents",
        element: (
          <ProtectedRoute
            permissions={['list_documents']}
            element={<Documents />}
          />
        )
      },
      {
        path: "documents/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_documents', 'edit_documents']}
            element={<DocumentsEdit />}
          />
        )
      },
      {
        path: "expenses",
        element: (
          <ProtectedRoute
            permissions={['list_expenses']}
            element={<Expenses />}
          />
        )
      },
      {
        path: "expenses/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_expenses', 'edit_expenses']}
            element={<ExpensesEdit />}
          />
        )
      },
      {
        path: "partners",
        element: (
          <ProtectedRoute
            permissions={['list_partners']}
            element={<Partners />}
          />
        )
      },
      {
        path: "partners/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_partners', 'edit_partners']}
            element={<PartnersEdit />}
          />
        )
      },
      {
        path: "payables",
        element: (
          <ProtectedRoute
            permissions={['list_payables']}
            element={<Payables />}
          />
        )
      },
      {
        path: "payables/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_payables', 'edit_payables']}
            element={<PayablesEdit />}
          />
        )
      },
      {
        path: "payments",
        element: (
          <ProtectedRoute
            permissions={['list_payments']}
            element={<Payments />}
          />
        )
      },
      {
        path: "payments/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_payments', 'edit_payments']}
            element={<PaymentsEdit />}
          />
        )
      },
      {
        path: "products",
        element: (
          <ProtectedRoute
            permissions={['list_products']}
            element={<Products />}
          />
        )
      },
      {
        path: "products/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_products', 'edit_products']}
            element={<ProductsEdit />}
          />
        )
      },
      {
        path: "pullouts",
        element: (
          <ProtectedRoute
            permissions={['list_pullouts']}
            element={<Pullouts />}
          />
        )
      },
      {
        path: "pullouts/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['list_pullouts']}
            element={<PulloutsEdit />}
          />
        )
      },
      {
        path: "purchases",
        element: (
          <ProtectedRoute
            permissions={['list_purchases']}
            element={<Purchases />}
          />
        )
      },
      {
        path: "purchases/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_purchases', 'edit_purchases']}
            element={<PurchasesEdit />}
          />
        )
      },
      {
        path: "sales",
        element: (
          <ProtectedRoute
            permissions={['list_sales']}
            element={<Sales />}
          />
        )
      },
      {
        path: "sales/edit/:id",
        Component: SalesEdit,
        element: (
          <ProtectedRoute
            permissions={['create_sales', 'edit_sales']}
            element={<SalesEdit />}
          />
        )
      },
      {
        path: "roles",
        element: (
          <ProtectedRoute
            permissions={['list_roles']}
            element={<Roles />}
          />
        )
      },
      {
        path: "roles/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_roles', 'edit_roles']}
            element={<RolesEdit />}
          />
        )
      },
      {
        path: "transactions",
        element: (
          <ProtectedRoute
            permissions={['list_services']}
            element={<Services />}
          />
        )
      },
      {
        path: "transactions/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_services', 'edit_services']}
            element={<ServicesEdit />}
          />
        )
      },
      {
        path: "transfer-stocks",
        element: (
          <ProtectedRoute
            permissions={['list_transfer_stocks']}
            element={<TransferStocks />}
          />
        )
      },
      {
        path: "users",
        element: (
          <ProtectedRoute
            permissions={['list_users']}
            element={<Users />}
          />
        )
      },
      {
        path: "vehicles",
        Component: Vehicles,
        element: (
          <ProtectedRoute
            permissions={['list_vehicles']}
            element={<Vehicles />}
          />
        )
      },
      {
        path: "vehicles/edit/:id",
        element: (
          <ProtectedRoute
            permissions={['create_vehicles', 'edit_vehicles']}
            element={<VehiclesEdit />}
          />
        )
      }
    ]
  },
  {
    path: "billing-pdf-hauling-waste",
    Component: BillingsHaulingWastePdf,
  },
  {
    path: "billing-pdf-quarry",
    Component: BillingsQuarryPdf,
  },
  {
    path: "billing-pdf-quarry-by-partner",
    Component: BillingsQuarryPartnerPdf,
  },
  {
    path: "billing-pdf-hauling-v1",
    Component: BillingsHaulingColoredWaterPdf,
  },
  {
    path: "cash-on-hand-pdf",
    Component: CashOnHandPdf,
  },
  {
    path: "unauthorized",
    Component: Unauthorized,
  },
];

export default admin_routes

function protectedLoader({ request }) {
  if (!isLoggedIn()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/auth/login?" + params.toString());
  }
  return null;
}
