import { createContext, Fragment, useContext, useState } from "react";
import { getRequest } from "utils/request";
import {initialState} from "./constant";

const DashboardContext = createContext(initialState)

const DashboardProvider = ({ children }) => {

  const [state, setState] = useState(initialState)

  const updateState = (newState) => {
    setState({
      ...state,
      ...newState
    });
  }

  const fetchData = async (type = "recent_sales") => {
    switch (type) {
      case "recent_sales":
        await fetchRecentSales()
        break;
      case "total_sales":
        await fetchTotal("total_sales")
        break;
      case "total_purchases":
        await fetchTotal("total_purchases")
        break;
      case "total_expenses":
        await fetchTotal("total_expenses")
        break;
      case "counts":
        await fetchCounts()
        break;
      case "chart_expenses":
        await fetchChartExpenses()
        break;
      case "chart_sales":
        await fetchChartSales()
        break;
    }
  }

  const fetchRecentSales = async () => {
    updateState({ is_loading_recent_sales: true })

    let params = `?branch_id=${state.branch_id}`
    params += `&user_id=${state.user_id}`

    let res = await getRequest(`/api/fetch-dashboard${params}`)

    if(res.ok) {
      updateState({
        recent_sales: res.data,
        is_loading_recent_sales: false
      });
    } else {
      updateState({ is_loading_recent_sales: false })
    }
  }

  const fetchTotals = async () => {
    if(state.is_loading_total) return true

    updateState({ is_loading_total: true })
    await fetchTotal("total_sales")
    // await fetchTotal("total_purchases")
    // await fetchTotal("total_expenses")
    updateState({ is_loading_total: false })
  }

  const fetchTotal = async (type = "total_sales") => {

    let url = `/api/fetch-dashboard?type=${type}`
    url += `&date=${state.total_date}`
    url += `&branch_id=${state.branch_id}`
    url += `&user_id=${state.user_id}`

    let res = await getRequest(url)

    if(res.ok) {
      updateState({
        [type]: res.data,
        [`is_loading_${type}`]: false
      });
    } else {
      updateState({ [`is_loading_${type}`]: false })
    }
  }

  const fetchCounts = async () => {
    let params = `?type=counts`
    params += `&branch_id=${state.branch_id}`
    params += `&user_id=${state.user_id}`

    let res = await getRequest(`/api/fetch-dashboard${params}`)

    if(res.ok) {
      updateState({ counts: res.data });
    }
  }

  const fetchChartExpenses = async () => {
    let params = `?type=chart_expenses`
    params += `&date=${state.chart_expenses_date.value}`
    params += `&branch_id=${state.branch_id}`
    params += `&user_id=${state.user_id}`

    let res = await getRequest(`/api/fetch-dashboard${params}`)

    if(res.ok) {
      updateState({ chart_expenses: res.data });
    }
  }

  const fetchChartSales = async () => {
    let params = `?type=chart_sales`
    params += `&date=${state.chart_sales_date.value}`
    params += `&branch_id=${state.branch_id}`
    params += `&user_id=${state.user_id}`

    let res = await getRequest(`/api/fetch-dashboard${params}`)

    if(res.ok) {
      updateState({ chart_sales: res.data });
    }
  }

  return (
    <DashboardContext.Provider value={{
      state,
      updateState,
      fetchData,
      fetchTotals
    }}>
      <Fragment>
        {children}
      </Fragment>
    </DashboardContext.Provider>
  )
}

const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if(!context) {
    throw new Error("useDashboardContext must be used within DashboardProvider")
  }
  return context;
}

export {DashboardProvider, useDashboardContext}
