import React, {Fragment} from "react";
import {Alert} from "antd";
import {FormItem} from "./styled";

const FormErrorMessage = ({ errors }) => {

  if(!errors || Object.keys(errors).length === 0) {
    return "";
  }

  return (
    <FormItem>
      <Alert
        message="Error"
        description={
          <>
            <ul>
              {Object.keys(errors).map((key) => {
                return(
                  <Fragment key={key}>
                    {errors[key].map((error, i) => {
                      return(
                        <li key={`${key}-${i}`}>
                          { error }
                        </li>
                      )
                    })}
                  </Fragment>
                )
              })}
            </ul>
          </>
        }
        type="error"
        showIcon
      />
    </FormItem>
  )
}

export default FormErrorMessage
