import React from "react";
import {Col3Wrapper, Root, RowWrapper} from "./styled";
import Header from "./components/Header";
import Totals from "./components/Totals";
import {DashboardProvider} from "contexts/DashboardProvider";

const Dashboard = () => {
  return (
    <DashboardProvider>
      <Root>
        <div>Great things coming soon.</div>
        <img alt={"coming-soon"} src={"/images/placeholders/comingsoon.svg"} />
        {/*<Header />*/}
        {/*<RowWrapper>*/}
        {/*  <Col3Wrapper>*/}
        {/*    <Totals />*/}
        {/*  </Col3Wrapper>*/}
        {/*  <Col3Wrapper>*/}

        {/*  </Col3Wrapper>*/}
        {/*  <Col3Wrapper>*/}

        {/*  </Col3Wrapper>*/}
        {/*</RowWrapper>*/}
      </Root>
    </DashboardProvider>
  )
}

export default Dashboard
