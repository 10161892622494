import React from "react";
import {Root} from "./styled";
import {LoadingOutlined} from "@ant-design/icons";

const LoggingOut = () => {
  return (
    <Root>
      <LoadingOutlined size="large" />
      <div>
        Logging out ...
      </div>
    </Root>
  )
}

export default LoggingOut
