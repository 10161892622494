import Login from "../pages/Auth/Login";
import {redirect} from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import {isLoggedIn} from "../utils/auth";

const auth_routes = [
  {
    path: "/auth",
    loader: loginLoader,
    Component: AuthLayout,
    children: [
      {
        path: "login",
        Component: Login,
      },
    ]
  },
]

export default auth_routes

async function loginLoader() {
  if (isLoggedIn()) {
    return redirect("/admin");
  }
  return null;
}
