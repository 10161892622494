import React from "react";
import {Spin} from "antd";
import {Root} from "./styled";

const SplashScreen = () => {
  return(
    <Root>
      <Spin size="large" />
    </Root>
  )
}
export default SplashScreen;
