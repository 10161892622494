import styled from "styled-components";
import {Drawer} from "antd";
import {colors} from "utils/colors";

export const RootDrawer = styled(Drawer)`
  border-radius: 10px;

  .ant-list-item-action {
    margin-inline-start: 2px;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    button {
      border-radius: 50%;
      font-size: 12px;
    }
  }

  .ant-drawer-body {
    padding: 14px;
  }

  .ant-list {
    .ant-list-item-meta-avatar > .anticon {
      font-size: 22px !important;
    }
    .ant-list-item-meta-title a {
      color: ${colors.gray[800]} !important;
      font-size: 14px;
      text-transform: capitalize;

      &:hover {
        color: ${colors.primary} !important;
      }
    }
    .ant-list-item-meta-description {
      font-size: 12px !important;
      color: ${colors.gray[600]} !important;
    }
    .ant-list-item-action button {
      background: ${colors.gray[800]};
      border-color: ${colors.gray[800]};
      color: #fff;
      border-radius: 6px;
      font-size: 12px;

      &:hover {
        color: ${colors.gray[800]};
        border-color: ${colors.gray[800]};
      }
    }
    .ant-list-item {
      background: ${colors.gray[100]};
      padding: 12px 8px 12px 12px;
      border-radius: 8px;
      margin-bottom: 4px;;
    }
  }
`

export const EmptyRoot = styled.div`
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`
