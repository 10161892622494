export const initialState = {
  loading: undefined,
  isLoadingData: true,
  isSaving: false,
  saveSuccess: false,
  isDeleting: false,
  isReady: false,
  errors: null,

  listAll: [],
  listData: [],
  listCount: 0,
  listParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 20,
  },
  listFilters: {
    searchText: "",
    branch_id: "all"
  },

  data: {
    id: "",
    role: "",
    name: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    avatar: "",
    branch_id: "",
  },

  managers: [],

  assignedCustomers: [],
  customerOptions: []
};
