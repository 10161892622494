import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  max-height: 680px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
`

export const FormWrapper = styled.div`
  width: 600px;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 30px 70px;
  background:rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FormHeader = styled.div`
  text-align: center;
  img {
    width: 110px;
  }
`

export const FormFooter = styled.div`
  text-align: center;
  padding-top: 20px;
  font-size: 11px;
  font-family: 'Quicksand';
  font-weight: 700;
  color: #a6a6a6;
`

export const FormContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 16px 0;
  
  > div {
    width: 100%;
    width: 100%;
    font-family: 'Quicksand';
    
    > div:first-child {
      font-size: 38px;
      font-weight: 600;
      text-align: center;
    }

    > div:nth-child(2) {
      font-size: 15px;
      font-weight: 200;
      margin: 8px 0 20px;
      text-align: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

      .ant-input-prefix {
      margin-inline-end: 14px;

      path {
        fill: #a6a6a6 !important;
      }
    }

    .ant-btn-primary {
      width: 100% !important;
      color: #fff !important;
      padding: 24px !important;
    }

    .ant-btn-primary.ant-btn-disabled,
    .ant-btn-primary:disabled {
      background: rgb(20 56 122 / 70%) !important;
    }

    button {
      .anticon {
        margin-right: 6px;
      }
    }
  }
`

export const ForgotPassword = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: right;
  padding: 4px 1px 12px;
  cursor: pointer;
  
  &:hover {
    color: #005ce6 !important;
  }
`

export const ErrorWrapper = styled.div`
  height: 50px;
`
