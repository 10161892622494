import styled from "styled-components";
import {Layout} from "antd";

const { Content } = Layout;

export const RootLayout = styled(Layout)`
  height: 100%;
  min-height: 100vh;
  background: #FFF !important;

  .ant-layout,
  .ant-layout-sider,
  .ant-layout-header {
    background: #FFF !important;
  }

  .ant-menu {
    border-inline-end: 0 !important;
  }

  .ant-layout-header {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .ant-layout-sider {
    height: 100vh;
    max-height: 100vh;
    position: sticky;
    top: 0;

    .ant-layout-sider-children {
      max-height: 100vh;
      display: flex;
      flex-direction: column;

      .ant-menu-item-selected {
        background-color: #e6eeff !important;
      }

      .ant-menu {
        flex-grow: 1;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset #d9d9d9;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #cccccc;
        }
      }
    }
  }
`

export const RootContent = styled(Content)`
  padding: 22px 20px;
  height: 100%;
  box-sizing: border-box !important;
  background: #EDEFF8;
  border-top-left-radius: 10px;
  position: relative;
`
