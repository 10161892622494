import styled from "styled-components";
import { Modal } from 'antd';

export const RootModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
  }
  .ant-modal-footer {
    padding: 0 35px 20px;
  }
`

export const Header = styled.div`
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #F1F1F1;
`

export const UserInfoWrapper = styled.div`
  width: 100%;
  padding: 10px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: -76px;
`

export const AvatarWrapper = styled.div`
  padding: 2px;

  .ant-avatar {
    border: 1px solid #00236D;
    font-weight: 600;
    background: #00236D !important;
  }
`

export const UserInfo = styled.div`
  flex-grow: 1;
  padding: 0 20px;
  
  > div:first-child {
    font-size: 22px;
    font-weight: 600;
  }

  > div:nth-child(2) {
    padding-top: 4px;
    font-weight: 600;
    color: #808080;
  }
`

export const Content = styled.div`
  flex-grow: 1;
  padding: 10px 36px 12px;
`

export const FormWrapper = styled.div`
  padding: 20px 44px 5px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
`
