import styled from "styled-components";
import {colors} from "utils/colors";

export const Root = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  flex-grow: 1;
  padding: 0 2px 24px;
`

export const Title = styled.div`
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
  color: #000;
`

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;  

  .ant-select-selector {
    border: 0 !important;
    box-shadow: unset !important;
  }

  .ant-select-selection-item {
    color: ${colors.primary};
  }
`
