import styled from "styled-components";
import {Layout} from "antd"

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  //.ant-menu {margin-bottom: 25px}
  .ant-menu-item {
    height: 34px !important;
  }
`

export const BrandWrapper = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 12px 4px;
  margin-bottom: 6px;
  transition: height 0.15s ease-in;

  &.collapsed {
    height: 68px;
    padding: 14px 10px;
    transition: height 0.15s ease-out;
  }
  img {
    height: 100%;
  }
`
