import {colors} from "utils/colors";

const theme = {
  token: {
    // fontFamily: "Quicksand",
    // fontFamily: "Inter",
    fontFamily: "Rubik",
    // fontFamily: "Nunito Sans",
    // fontFamily: "Arimo",
    // fontFamily: "Poppins",
    colorText: "#000",
    colorPrimary: colors.primary,
  }
}

export default theme
