const date_filters = [
  {value: "today", label: "Today"},
  {value: "yesterday", label: "Yesterday"},
  {value: "this_week", label: "This Week"},
  {value: "last_week", label: "Last Week"},
  {value: "this_month", label: "This Month"},
  {value: "last_month", label: "Last Month"},
  {value: "this_year", label: "This Year"},
  {value: "last_year", label: "Last Year"},
]

const filterByOptions = [
  {value: "branch", label: "Filter By Branch"},
  {value: "manager", label: "Filter By  Manager"},
]

export const initialState = {

  filterBy: "branch",
  filterByOptions: filterByOptions,
  branch_id: "",
  user_id: "",

  date_filters: date_filters,

  is_loading_recent_sales: false,
  recent_sales: [],

  is_loading_total_sales: false,
  total_sales_date: {value: "today", label: "Today"},
  total_sales: 0,

  is_loading_total_purchases: false,
  total_purchases_date: {value: "today", label: "Today"},
  total_purchases: 0,

  total_date: "today",
  is_loading_total: false,

  is_loading_total_expenses: false,
  total_expenses_date: {value: "today", label: "Today"},
  total_expenses: 0,

  counts: {
    products: 0,
    suppliers: 0,
    customers: 0,
    users: 0,
  },

  chart_expenses: {},
  chart_expenses_date: {value: "this_month", label: "This Month"},

  chart_sales_date: {value: "this_month", label: "This Month"},
  chart_sales: {},

};
