import moment from "moment";

export const formatNumber = (number, decimal = 2) => {
  return (parseFloat(number)).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: decimal,
      maximumFractionDigits:decimal
    }
  );
}

export const formatDate = (date = "") => {
  if(!date) return ""
  return moment(date).format("YYYY-MM-DD");
}
