import {createContext, Fragment, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {
  deleteRequest,
  getRequest,
  postRequest
} from "utils/request";
import {initialState} from "./constant";
import {pluck} from "utils/functions";

const UserContext = createContext(initialState)

const UserProvider = ({ children }) => {

  const navigate = useNavigate()
  const [state, setState] = useState(initialState)

  const updateState = (newState) => {
    setState({
      ...state,
      ...newState
    });
  }

  const updateData = (key, value) => {
    updateState({
      data: {
        ...state.data,
        [key]: value
      }
    })
  }

  const updateListParams = (newParams) => {
    updateState({
      listParams: {
        ...state.listParams,
        ...newParams
      }
    })
  }

  const updateListFilters = (newFilters) => {
    updateState({
      listFilters: {
        ...state.listFilters,
        ...newFilters
      }
    })
  }

  const resetSorting = () => {
    updateState({
      listParams: {
        ...state.listParams,
        sortField: initialState.listParams.sortField,
        sortOrder: initialState.listParams.sortOrder
      }
    })
  }

  const fetchAll = async (branch_id = "") => {
    updateState({ loading: true });
    let res = await getRequest(`/api/fetch-users?branch_id=${branch_id}`);
    if(res.ok) {
      updateState({
        listAll: res.data.data,
        loading: false
      });
    } else {
      updateState({ loading: false });
    }
  }

  const fetchList = async () => {
    if(state.loading) return

    updateState({
      loading: true,
      errors: null,
      saveSuccess: false,
      isLoadingData: true
    });

    let params = `?page=${state.listParams.pageNumber}`
    params += `&pageSize=${state.listParams.pageSize}`
    params += `&sortField=${state.listParams.sortField}`
    params += `&sortOrder=${state.listParams.sortOrder}`
    params += `&searchText=${state.listFilters.searchText}`
    params += `&branch_id=${state.listFilters.branch_id !== "all" ? state.listFilters.branch_id : ""}`

    let res = await getRequest(`/api/users${params}`);

    if(res.ok) {
      updateState({
        loading: undefined,
        isReady: true,
        errors: null,
        saveSuccess: false,
        isLoadingData: true,
        listData: res.data.data,
        listCount: res.data.meta.total
      });
    } else {
      updateState({ loading: undefined });
    }
  }

  const show = async (id) => {
    updateState({
      isLoadingData: true,
      saveSuccess: false,
    })
    let res = await getRequest(`/api/users/${id}`);

    if(res.ok) {
      let data = {
        ...res.data.data,
        branch_id: parseInt(res.data.data.branch_id),
        role: res.data.data.roles.length > 0 ? res.data.data.roles[0].name : ""
      }
      updateState({
        data: data,
        isLoadingData: undefined,
        saveSuccess: false,
      });
      return data
    } else {
      updateState({ isLoadingData: undefined })
    }
  }

  const save = async (payload) => {
    updateState({
      errors: null,
      isSaving: true,
      saveSuccess: false
    });

    let url = `/api/users`;
    delete payload.roles;

    if(payload.id) {
      url += `/${payload.id}`;
      payload['_method'] = 'PUT';
    }

    const formData = new FormData()
    for(let key in payload) {
      formData.append(key, payload[key])
    }

    // const config = {headers: { "Content-Type": "multipart/form-data" }}
    let res = await postRequest(url, payload)

    if(res.ok) {
      updateState({
        isSaving: false,
        saveSuccess: true,
        errors: null,
      });

      return true;
    } else {
      if(res.hasOwnProperty('status') && res.status === 422) {
        updateState({
          errors: res.data.errors,
          isSaving: false,
        });
        return false;
      }
    }
  }

  const deleteData = async (id) => {
    let res = await deleteRequest(`/api/users/${id}`)
    if(res.ok) {
      message.success("User successfully deleted.")
      await fetchList();
    }
  }

  const fetchManagers = async () => {
    let res = await getRequest(`/api/fetch-managers`);

    if(res.ok) {
      updateState({ managers: res.data });
    }
  }

  const addAssignCustomerItem = () => {
    updateState({
      assignedCustomers: [
        ...state.assignedCustomers,
        {
          id: "",
          name: "",
          new: true
        }
      ]
    })
  }

  const updateAssignCustomerItem = (index, value, customers) => {
    let temp = JSON.parse(JSON.stringify(state.assignedCustomers))
    temp = temp.map((item, i) => {
      if(i === index) {
        item = {
          ...item,
          ...value
        }
      }
      return item
    })

    let newCustomerOptions = getCustomerOptions(customers, temp)

    updateState({
      customerOptions: newCustomerOptions,
      assignedCustomers: temp
    })
  }

  const deleteAssignCustomerItem = (index, customers) => {
    let temp = JSON.parse(JSON.stringify(state.assignedCustomers))
    temp = temp.filter((item, i) => i !== index)

    let newCustomerOptions = getCustomerOptions(customers, temp)

    updateState({
      customerOptions: newCustomerOptions,
      assignedCustomers: temp
    })
  }

  const getCustomerOptions = (customers, newAssignedCustomers) => {
    let options = customers
    let ids = pluck(newAssignedCustomers, "id")
    if(ids.length > 0) {
      options = options.filter(item => !ids.includes(item.id))
    }
    return options
  }

  return (
    <UserContext.Provider value={{
      navigate,
      state,
      updateState,
      updateData,
      updateListParams,
      updateListFilters,
      resetSorting,
      fetchAll,
      fetchList,
      show,
      save,
      deleteData,
      fetchManagers,
      addAssignCustomerItem,
      updateAssignCustomerItem,
      deleteAssignCustomerItem,
      getCustomerOptions
    }}>
      <Fragment>
        {children}
      </Fragment>
    </UserContext.Provider>
  )
}

const useUserContext = () => {
  const context = useContext(UserContext);
  if(!context) {
    throw new Error("useUserContext must be used within UserProvider")
  }
  return context;
}

export {UserProvider, useUserContext}
