import React from "react";
import {Alert, Form} from "antd";

const FormSuccessMessage = ({
  success,
  message = "Changes successfully saved."
}) => {

  if(!success) {
    return "";
  }

  return (
    <Form.Item>
      <Alert
        message={message}
        type="success"
        showIcon
      />
    </Form.Item>
  )
}

export default FormSuccessMessage
