import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { Menu } from 'antd';
import {menuItems} from "./constant";
import {BrandWrapper, StyledSider} from "./styled";
import {hasPermissions} from "utils/auth";

const Sidebar = ({ collapsed, isReady, setIsReady }) => {

  const navigate = useNavigate()
  const location = useLocation()
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [userMenuItems, setUserMenuItems] = useState([])

  useEffect(() => {
    let uri = location.pathname.split("/")
    let key = uri[uri.length - 1]
    let openKey = ""

    if(uri.length === 2) {
      key = "dashboard"
      navigate("/admin/dashboard")
    }

    let tempItems = []
    for(let item of menuItems) {
      if(item?.permissions && item.permissions.length > 0) {
        if(hasPermissions(item.permissions)) {
          if(item?.children && item.children.length > 0) {
            item.children = JSON.parse(JSON.stringify(item.children))
              .filter(subItem => hasPermissions(subItem.permissions))
          }
          tempItems.push(item)
        }
      } else {
        if(item?.children && item.children.length > 0) {
          item.children = JSON.parse(JSON.stringify(item.children))
            .filter(subItem => hasPermissions(subItem.permissions))
        }
        tempItems.push(item)
      }
    }

    setUserMenuItems(tempItems)

    for(let item of menuItems) {
      if(item.hasOwnProperty('children') && item.children.length > 0) {
        for(let subItem of item.children) {
          if(subItem.key === key) {
            openKey = item.key
            break
          }
        }
      }
      if(openKey) break
    }

    setOpenKeys([openKey])
    setSelectedKeys([key])
    setIsReady(true)
  }, [])


  const handleOnClick = (item) => {
    setSelectedKeys(item.key)
    navigate(`/admin/${item.key}`)
  }

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed}>
      {isReady && (
        <BrandWrapper className={collapsed ? "collapsed" : ""}>
          <img alt={"SSM Logo"} src={"/images/logo.png"} />
        </BrandWrapper>
      )}
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        items={userMenuItems}
        onClick={handleOnClick}
        onOpenChange={(keys) => setOpenKeys(keys)}
      />
    </StyledSider>
  );
};
export default Sidebar;
