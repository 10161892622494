import styled from "styled-components";

export const Root = styled.div`
  background: url('/images/bg/image1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
