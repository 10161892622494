import React, {useEffect, useState} from "react";
import {EmptyRoot, RootDrawer} from "./styled";
import {Button, Empty, List} from 'antd';
import {Link} from "react-router-dom";
import {ExclamationCircleTwoTone, ClockCircleTwoTone} from "@ant-design/icons"
import {useDocumentContext} from "contexts/DocumentProvider";
import {colors} from "utils/colors";
import dayjs from "dayjs";
import {formatNumber} from "utils/formatter";

const DrawerNotification = ({
  openDrawer,
  toggleDrawer
}) => {
  const {state, fetchAllExpiry, markedAsDone} = useDocumentContext()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const init = async () => {
      await fetchAllExpiry()
      setIsReady(true)
    }
    init()
  }, [])

  return (
    <RootDrawer
      title="Reminders"
      onClose={toggleDrawer}
      open={openDrawer}
      width={450}
    >
      {(state.listAllExpiry.hasOwnProperty('count') && state.listAllExpiry.count === 0) && (
        <EmptyRoot>
          <Empty description={"No Data Available"} />
        </EmptyRoot>
      )}
      {(state.listAllExpiry?.expiringSoon && state.listAllExpiry?.expiringSoon.length > 0) && (
        <List
          itemLayout="horizontal"
          dataSource={state.listAllExpiry.expiringSoon}
          renderItem={(item, index) => (
            <List.Item
              key={index}
              actions={[
                <Button size={"small"} type={"default"}
                  onClick={() => markedAsDone(item)}
                  loading={item.loading}
                >
                  mark as done
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<ClockCircleTwoTone twoToneColor={colors.danger} />}
                title={
                  <Link to={`documents/edit/${item.id}`} target={"_blank"}>
                    {item.document_type.replace("_", " ")} | {item.issuing_authority}
                  </Link>
                }
                description={<div>
                  <div>Expiry Date: {dayjs(item.expiry_date).format('MMMM D, YYYY')}</div>
                </div>}
              />
            </List.Item>
          )}
        />
      )}
      {(state.listAllExpiry?.payables && state.listAllExpiry?.payables.length) > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={state.listAllExpiry.payables}
          renderItem={(item, index) => (
            <List.Item
              key={index}
              actions={[
                <Link to={`payables/edit/${item.id}`} target={"_blank"}>
                  <Button size={"small"} type={"default"} loading={item.loading}>
                    mark as done
                  </Button>
                </Link>
              ]}
            >
              <List.Item.Meta
                avatar={<ExclamationCircleTwoTone twoToneColor={colors.primary} />}
                title={
                  <Link to={`payables/edit/${item.id}`} target={"_blank"}>
                    {item.company_name}
                  </Link>
                }
                description={<div>
                  <div>Amount: {formatNumber(item.amount)}</div>
                  <div>Coverage: {item.date_from}{" "}to{" "}{item.date_to}</div>
                </div>}
              />
            </List.Item>
          )}
        />
      )}
    </RootDrawer>
  )
}

export default DrawerNotification
