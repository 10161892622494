import React, {useEffect, useState} from 'react';
import { Avatar, Form, Input } from 'antd'
import { LockOutlined } from '@ant-design/icons';
import {
  AvatarWrapper,
  Content,
  FormWrapper,
  Header,
  RootModal,
  UserInfo,
  UserInfoWrapper
} from "./styled";
import {useAuthContext} from "contexts/AuthProvider";
import {useUserContext} from "contexts/UserProvider";
import {getUserRole} from "utils/auth";
import FormErrorMessage from "components/FormErrorMessage";
import FormSuccessMessage from "components/FormSuccessMessage";

const avatarSize = {
  xs: 120,
  sm: 120,
  md: 120,
  lg: 120,
  xl: 120,
  xxl: 120,
}

const ModalUserProfile = ({
  open,
  toggleOpen
}) => {
  const { state } = useAuthContext()
  const { save, updateState } = useUserContext()

  const [success, setSuccess] = useState(false)

  const handleSave = async () => {
    await save()
  }

  const onClose = () => {
    updateState({ saveSuccess: false })
    toggleOpen()
  }

  return (
    <RootModal
      open={open}
      okText={"Update profile"}
      onCancel={onClose}
      onOk={handleSave}
      width={700}
    >
      <Header />
      <UserInfoWrapper>
        <AvatarWrapper>
          {state.user.avatar ? (
            <Avatar
              size={avatarSize}
              src={state.user.avatar}
            />
          ) : (
            <Avatar size={avatarSize}>
              {state.user.name.charAt(0)}
            </Avatar>
          )}
        </AvatarWrapper>
        <UserInfo>
          <div>{state.user.name}</div>
          <div>{state.user.email} | {getUserRole()}</div>
        </UserInfo>
      </UserInfoWrapper>
      <Content>
        {open && <ModalContent user_id={state.user.id} />}
      </Content>
    </RootModal>
  );
};

export const ModalContent = ({
  user_id
}) => {

  const {
    state,
    show,
    updateData
  } = useUserContext()

  useEffect(() => {
    show(user_id)
  }, [user_id])

  return (
    <FormWrapper>
      <Form
        layout="vertical"
        autoComplete="off"
      >
        <FormSuccessMessage success={state.saveSuccess} />
        <FormErrorMessage errors={state.errors} />
        <Form.Item label="Full name">
          <Input
            size="large"
            placeholder="Full name"
            autoComplete={"off"}
            rules={[{ required: true }]}
            value={state.data.name}
            onChange={(event) => updateData("name", event.target.value)}
          />
        </Form.Item>
        <Form.Item label="Email address">
          <Input
            size="large"
            placeholder="Email address"
            autoComplete={"off"}
            rules={[{ required: true }]}
            value={state.data.email}
            onChange={(event) => updateData("email", event.target.value)}
          />
        </Form.Item>
        <Form.Item label="Password" validateStatus="success">
          <Input
            type={"password"}
            size="large"
            placeholder="Password"
            prefix={<LockOutlined />}
            autoComplete={"off"}
            value={state.data.password}
            onChange={(event) => updateData("password", event.target.value)}
          />
        </Form.Item>
        <Form.Item label="Confirm password">
          <Input
            type={"password"}
            size="large"
            placeholder="Confirm password"
            prefix={<LockOutlined />}
            autoComplete={"off"}
            value={state.data.password_confirmation}
            onChange={(event) => updateData("password_confirmation", event.target.value)}
          />
        </Form.Item>
      </Form>
    </FormWrapper>
  )
}
export default ModalUserProfile;
